export const deepCopy = (src, dest) => {
    for (const key in src) {
        if (typeof src[key] !== 'object' || src[key] instanceof Date) {
            //est une feuille
            dest[key] = src[key];
        } else {
            if (!dest[key]) {
                if (Array.isArray(src[key])) {
                    dest[key] = [];
                } else {
                    dest[key] = {};
                }
            }
            deepCopy(src[key], dest[key]);
        }
    }
};

const testPath = (obj, ...args) => {
    for (const key of args) {
        if (obj && obj[key] !== undefined) {
            obj = obj[key];
        } else {
            return;
        }
    }
    return obj;
};

const equalsObject = (obj1, obj2) => {
    // Si les types sont différents, les objets ne peuvent pas être égaux
    if (typeof obj1 !== typeof obj2) {
        return false;
    }

    // Si les types sont primitifs, nous pouvons utiliser l'opérateur ===
    if (typeof obj1 !== 'object' || obj1 === null) {
        return obj1 === obj2;
    }

    // Comparaison des propriétés pour les objets
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        if (!equalsObject(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
};

export default {
    deepCopy,
    testPath,
    equalsObject,
};
