export enum contexts {
    GLOBAL = 'global',
    PRODUCTION = 'prod',
    STAGING = 'staging',
    PREVIEW = 'preview',
    DEV = 'dev', //en general, correspond a un branchement avec le saas des outils noCode
    LOCAL = 'local',
    PRODUCTION_LOCAL = 'prod-local', //we build in local but we deploy to prod
}

export const contextsShapes = new Proxy(
    {
        [contexts.STAGING]: 'round',
        [contexts.PRODUCTION]: 'triangle',
    },
    {
        get(target, p: string, receiver) {
            if (!target[p]) {
                return 'round';
            }
            return target[p];
        },
    }
);

export const contextsDisplayNames = new Proxy(
    {
        [contexts.STAGING]: 'Staging',
        [contexts.PRODUCTION]: 'Production',
    },
    {
        get(target, p: string, receiver) {
            if (!target[p]) {
                return `${p[0].toUpperCase()}${p.slice(1)}`;
            }
            return target[p];
        },
    }
);

export enum sizeConfig {
    STAGING = 'staging',
    PRODUCTION = 'prod',
}

export const BASE_CONTEXTS = [contexts.PRODUCTION, contexts.STAGING];
